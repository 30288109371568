import React from "react";

const App = () => {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>$MYSPACE &mdash; a space for friends.</title>

        <link rel="preconnect" href="https://static.spacehey.net" crossOrigin />
        <link rel="preconnect" href="https://cdn.spacehey.net" crossOrigin />

        <script src="https://spacehey.com/js/script.js?v=0.39" async></script>

        <link rel="stylesheet" href="https://spacehey.com/css/normalize.css" />
        <link rel="stylesheet" href="https://spacehey.com/css/my.css?c=c09624eb0d3d0ea2c126e2a1a7dfcb9dee7ca101f4d47efaf081a661643fe1aa" />

        <link rel="dns-prefetch" href="https://static.spacehey.net" />
        <link rel="dns-prefetch" href="https://cdn.spacehey.net" />

        <link rel="shortcut icon" type="image/x-icon" href="https://spacehey.com/favicon.ico?v=2" />

        <meta name="title" content="$MYSPACE." />
        <meta name="description" content="MySpace is a retro social network focused on privacy and customizability. It's a friendly place to have fun, meet friends, and be creative. Join for free!" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="SpaceHey &mdash; a space for friends." />
        <meta property="og:description" content="MySpace is a retro social network focused on privacy and customizability. It's a friendly place to have fun, meet friends, and be creative. Join for free!" />
        <meta property="og:image" content="https://spacehey.com/img/meta_o.png" />
        <meta property="og:site_name" content="SpaceHey" />

        <meta property="twitter:site" content="@spacehey" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="SpaceHey &mdash; a space for friends." />
        <meta property="twitter:description" content="SpaceHey is a retro social network focused on privacy and customizability. It's a friendly place to have fun, meet friends, and be creative. Join for free!" />
        <meta property="twitter:image" content="https://spacehey.com/img/meta_o.png" />
        <meta name="apple-itunes-app" content="app-id=6464676228" />
        <link rel="apple-touch-icon" sizes="180x180" href="https://spacehey.com/img/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://spacehey.com/img/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://spacehey.com/img/favicons/favicon-16x16.png" />
        <link rel="mask-icon" href="https://spacehey.com/img/favicons/safari-pinned-tab.svg" color="#1D4ED8" />
        <link rel="manifest" href="https://spacehey.com/img/favicons/site.webmanifest" />
        <meta name="apple-mobile-web-app-title" content="SpaceHey" />
        <meta name="application-name" content="SpaceHey" />
        <meta name="msapplication-TileColor" content="#1D4ED8" />
        <meta name="msapplication-config" content="https://spacehey.com/img/favicons/browserconfig.xml" />
        <meta name="theme-color" content="#1D4ED8" />

        <link href="https://spacehey.com/opensearch.xml" rel="search" title="Search SpaceHey" type="application/opensearchdescription+xml" />

        <link rel="canonical" href="https://spacehey.com" />
        {/*<script type="application/ld+json">[{"@context":"https:\/\/schema.org","@type":"Organization","name":"SpaceHey","slogan":"a space for friends","url":"https:\/\/spacehey.com","logo":"https:\/\/spacehey.com\/img\/logo_square.png","email":"support@spacehey.com","sameAs":["https:\/\/twitter.com\/spacehey","https:\/\/www.instagram.com\/spacehey","https:\/\/www.facebook.com\/spacehey","https:\/\/www.youtube.com\/spacehey","https:\/\/www.tiktok.com\/@spacehey","https:\/\/www.wikidata.org\/wiki\/Q107123627","https:\/\/en.wikipedia.org\/wiki\/Spacehey"],"parentOrganization":{"@type":"Organization","name":"Tibush GmbH","url":"https:\/\/tibush.com","logo":"https:\/\/tibush.com\/images\/logo.png","email":"info@tibush.com"}},{"@context":"https:\/\/schema.org","@type":"WebSite","url":"https:\/\/spacehey.com","potentialAction":{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"https:\/\/spacehey.com\/search?q={search_term_string}"},"query-input":"required name=search_term_string"}}]</script>*/}
      </head>
      <body data-base="https://spacehey.com">
        <div className="container">
          <nav className="">
            <div className="flex w-full items-center justify-between bg-[#039] px-4 py-2">
              <img src="https://web.archive.org/web/20070109213611im_/http://x.myspace.com/images/LogoDotcom.gif" alt="SpaceHey" fetchpriority="high" className="w-[205px]" />
              <div className="flex items-center gap-2">
                <label>Search:</label>
                <input className="px-[4px] py-[2px] border border-black rounded-sm text-black text-[12px]" type="text" autoComplete="off" />

                <button className="bg-[rgb(239,239,239)] text-black px-[4px] py-[2px] rounded-sm border border-black">Search</button>
              </div>
              <div className="flex gap-1">
                <a className=" font-[12px] cursor-pointer text-white hover:underline hover:text-white">Help</a>
                <div className="text-white cursor-default"> | </div>
                <a className=" font-[12px] cursor-pointer text-white hover:underline hover:text-white">SignUp</a>
              </div>
            </div>
            <div className="bg-[#60A5FA] flex flex-wrap items-center gap-[5px] py-[3.5px] px-[16px] overflow-visible">
              <a className="font-bold font-[12px] cursor-pointer text-white hover:underline hover:text-black">Home</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Browse</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Search</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Invite</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Mail</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Blog</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Favorites</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Forum</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Groups</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Events</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Games</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Music</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">Classifieds</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-[12px] cursor-pointer text-white hover:underline hover:text-black">SignUp</a>
            </div>
          </nav>
          <main>
            <div className="row home">
              <div className="col w-60 left">
                <div className="new-people cool">
                  <div className="top">
                    <h4 className="font-bold">Cool People</h4>
                  </div>
                  <div className="inner">
                    <div className="person ">
                      <a target="_blank" href="https://twitter.com/traderpow">
                        <p>pow 🧲</p>
                      </a>
                      <a target="_blank" href="https://twitter.com/traderpow">
                        <img className="pfp-fallback" src="https://pbs.twimg.com/profile_images/1721740253026033664/jD4__sqk_400x400.jpg" alt="pow's profile picture" loading="lazy" style={{ aspectRatio: "1/1" }} />
                      </a>
                    </div>
                    <div className="person ">
                      <a target="_blank" href="https://twitter.com/naseem">
                        <p>naseem</p>
                      </a>
                      <a target="_blank" href="https://twitter.com/naseem">
                        <img className="pfp-fallback" src="https://pbs.twimg.com/profile_images/1697623966930677760/x3pQATtf_400x400.jpg" alt="naseem's profile picture" loading="lazy" style={{ aspectRatio: "1/1" }} />
                      </a>
                    </div>
                    <div className="person ">
                      <a target="_blank" href="https://twitter.com/icedknife">
                        <p>Iced</p>
                      </a>
                      <a target="_blank" href="https://twitter.com/icedknife">
                        <img className="pfp-fallback" src="https://pbs.twimg.com/profile_images/1650746818798444544/OaAb9GI0_400x400.jpg" alt="scoobyjb's profile picture" loading="lazy" style={{ aspectRatio: "1/1" }} />
                      </a>
                    </div>
                    <div className="person ">
                      <a target="_blank" href="https://twitter.com/jpeggler">
                        <p>jpeggler</p>
                      </a>
                      <a target="_blank" href="https://twitter.com/jpeggler">
                        <img className="pfp-fallback" src="https://pbs.twimg.com/profile_images/1751066514332000256/G0i2-1B7_400x400.jpg" alt="jpeggler's profile picture" loading="lazy" style={{ aspectRatio: "1" }} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="specials">
                  <div className="heading">
                    <h4 className="font-bold">$MYSPACE Info!</h4>
                  </div>
                  <div className="inner">
                    <div className="image">
                      <img src="https://imgs.search.brave.com/bOQ-qzV08dkGldJBYutXM3bxwfz4-n9oVoMIsVqFJU0/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9zdGFr/aW5nY3J5cHRvLmlu/Zm8vc3RhdGljL2Fz/c2V0cy9jb2lucy9z/b2xhbmEtbG9nby5w/bmc_dj0xMDE" alt="SpaceHey Merchandise Photo" loading="lazy" />
                    </div>
                    <div className="details" lang="en">
                      <p>
                        <div className="flex gap-1">
                          <div className="font-semibold">Twitter -</div>
                          <a target="_blank" href="https://twitter.com/MySpace_Solana">
                            twitter.com/MySpace_Solana
                          </a>
                        </div>
                        <div className="flex gap-1">
                          <div className="font-semibold">Telegram -</div>
                          <a target="_blank" href="https://t.me/myspace_portal">
                          t.me/myspace_portal
                          </a>
                        </div>
                        <div className="flex gap-1">
                          <div className="font-semibold">Starting LP -</div>
                          10 SOL
                        </div>
                        <div className="flex gap-1">
                          <div className="text-xs">8waGcb2vHZgiyNL4tyNy1juoaDdBMNLhg2GZyKJdttsF</div>
                        </div>
                      </p>
                      <p>
                        <b>
                          &raquo; <a target="_blank" href="https://birdeye.so/token/8waGcb2vHZgiyNL4tyNy1juoaDdBMNLhg2GZyKJdttsF?chain=solana">Buy Now</a>
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="music">
                  <div className="heading">
                    <h4 className="font-bold">Announcements</h4>
                  </div>
                  <div className="inner">
                    <div className="cover">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHOR7tV3LjANLuZqrfNktEzDzQ19MzsBCpNA0Ln8Knfw&s" alt="cover" loading="lazy" />
                    </div>
                    <div className="details">
                      <h4>
                        <a className="font-bold">$MYSPACE listed on Birdeye and Raydium!</a>
                      </h4>
                      <p>
                        $MYSPACE is making moves in the solana communities. Many DAOs are picking up on the coin since it's recent launch.
                      </p>
                      <p>
                        <b>
                          &raquo; <a target="_blank" href="https://birdeye.so/token/8waGcb2vHZgiyNL4tyNy1juoaDdBMNLhg2GZyKJdttsF?chain=solana">Visit Birdeye</a>
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col right">
                <div>
                  <div className="p-[5px] bg-[#039] text-white font-bold text-[11px]">Member Login</div>
                  <div className="flex flex-col items-center gap-1 border border-black w-full pt-4">
                    <div className="flex items-center gap-[24px]">
                      <label className="mx-auto text-[11px]">E-Mail :</label>
                      <input type="text" className="py-[1px] px-[2px] border border-black rounded-sm " />
                    </div>
                    <div className="flex items-center gap-2">
                      <label className="mx-auto text-[11px]">Password :</label>
                      <input type="password" className="py-[1px] px-[2px] border border-black rounded-sm " />
                    </div>
                    <div className="flex items-center gap-2 mt-2">
                      <input type="checkbox" name="remember" />
                      <div className="text-[11px]">Remember my E-mail</div>
                    </div>

                    <div className="flex mt-2 gap-2">
                      <button className="text-[#4368B4] font-[12.8px] border border-black px-[5px] py-[2px]">LOGIN</button>
                      <button className="bg-[#ED0707] text-white font-bold font-[12.8px] border border-black px-[5px] py-[2px]">SIGN UP!</button>
                    </div>
                    <a className="forgot cursor- mt-2 mb-4 cursor-pointer">Forgot your password?</a>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="p-[5px] bg-[#903] text-white font-bold text-[11px]">Videos</div>
                  <div className="border border-black flex p-2 gap-[5%]">
                    <img src="https://i.imgur.com/jBnHXY4.png" className="aspect-square w-[47.5%]" />
                    <div className="w-[47.5%] flex flex-col justify-between py-4">
                      <a className="text-[#039] text-[11px] font-bold hover:underline" href="https://i.imgur.com/o2u357S.mp4" target="_blank">
                        {" "}
                        Skibidi Bop Yes Yes Yes
                      </a>
                      <div className="text-black text-[8.8px]">Bop, bop, bop, bop Yes, yes, yes, yes Skibidi, skibidi bop— Bop, bop, bop, bop Yes, yes, yes, yes Skibidi</div>
                      <b>
                        &raquo;{" "}
                        <a href="https://i.imgur.com/o2u357S.mp4" target="_blank" className="text-[11px]">
                          Watch Now
                        </a>
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row info-area">
              <div className="col info-box">
                <h3 className="text-[#003399] font-bold text-[10.89px]">Get Started On MySpace!</h3>
                <p style={{ fontSize: "10px" }}>Join for free, and view profiles, connect with others, blog, rank music, and much more!</p>
                <p className="link">
                  &raquo;{" "}
                  <a style={{ fontSize: "10px" }} href="" title="">
                    Learn More
                  </a>
                </p>
              </div>
              <div className="col info-box">
                <h3 className="text-[#003399] font-bold text-[10.89px]">Create Your Profile!</h3>
                <p style={{ fontSize: "10px" }}>Tell us about yourself, upload your pictures, and start adding friends to your network.</p>
                <p style={{ fontSize: "10px" }} className="link">
                  &raquo;{" "}
                  <a href="" title="">
                    Start Now
                  </a>
                </p>
              </div>
              <div className="col info-box">
                <h3 className="text-[#003399] font-bold text-[10.89px]">Browse Through Profiles!</h3>
                <p style={{ fontSize: "10px" }}>Read through millions of profiles on MySpace! See pix, read blogs, and more!</p>
                <p style={{ fontSize: "10px" }} className="link">
                  &raquo;{" "}
                  <a href="" title="">
                    Browse Now
                  </a>
                </p>
              </div>
              <div className="col info-box">
                <h3 className="text-[#003399] font-bold text-[10.89px]">Invite Your Friends!</h3>
                <p style={{ fontSize: "10px" }}>Invite your friends, and as they invite their friends your network will grow even larger!</p>
                <p style={{ fontSize: "10px" }} className="link">
                  &raquo;{" "}
                  <a href="" title="">
                    Invite Friends Now
                  </a>
                </p>
              </div>
            </div>
          </main>
          <footer>
            <div className="flex flex-wrap items-center gap-[5px] py-[3.5px] px-[16px] overflow-visible">
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">About</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">FAQ</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">Terms</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">Privacy</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">Safety Tips</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">Contact MySpace</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">Promote!</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">Advertise</a>
              <div className="text-black cursor-default"> | </div>
              <a className="font-bold font-[12px] cursor-pointer text-[#039] hover:underline hover:text-[#CF040B]">MySpace International</a>
            </div>

            <a className="text-black text-[11px]">©2003-2007 $MYSPACE. All Rights Reserved.</a>
          </footer>
        </div>

        <link rel="stylesheet" href="https://spacehey.com/css/important.css?c=994aafba76bf9205f4f293837ea21a3ecdfd1dbc4223ec4de3cac4e15c853257" />
      </body>
    </html>
  );
};

export default App;
